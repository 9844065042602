import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Slider from "react-slick"
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import "slick-carousel/slick/slick.css";
import '~/styles/ProductCardSlider.scss'

import '~/styles/MachinePage.scss'

import DownloadIcon from '~/svgs/download.svg'

import DeliveryIcon from '~/svgs/truck.svg'
import SupportIcon from '~/svgs/factory.svg'
import GuaranteeIcon from '~/svgs/plan.svg'

import SEO from '~/components/seo'

const MachinePage = ({ data }) => {
  const machine = data.markdownRemark

  const [totalSlides, setTotalSlides] = useState(machine.frontmatter.images.length)
  const [currentSlide, setCurrentSlide] = useState(1)

  var introSliderSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: current => setCurrentSlide(current + 1),
    //onInit: slideCount => setTotalSlides(slideCount)
  };

  return (
    <>
      <SEO title={machine.frontmatter.title} description={machine.frontmatter.description} />
      <Helmet
        bodyAttributes={{
          class: "product-page"
        }}
      />
      <main className="machine">
        <section className="machine-intro">
          <div className="machine-intro-text">
          <div className="machine-intro-text-title">
            {machine.frontmatter.title}
            </div>
            <div className="machine-intro-text-description">
              {machine.frontmatter.description}
            </div>
            <div className="machine-intro-text-actions">
              <a className="machine-intro-text-actions-button" href="mailto:info@pahhk.com">Contact us</a>
              <a className="machine-intro-text-actions-link" target="_blank" href={machine.frontmatter.presentation.publicURL}>
                <DownloadIcon className="machine-intro-text-actions-link-icon" />
                <span className="machine-intro-text-actions-link-text">Download presentation</span>
              </a>
            </div>
          </div>
          <div className="machine-intro-image">
            <Slider className="machine-intro-image-slider" {...introSliderSettings}>
              {machine.frontmatter.images.map(image => (
                <div className="machine-intro-image-slider-item">
                  <Img
                    className="machine-intro-image-slider-item-image"
                    fluid={image.childImageSharp.fluid}
                    alt={machine.frontmatter.title}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              ))}
            </Slider>
            <div className="machine-intro-image-counter"><span className="machine-intro-image-counter-total">{currentSlide}</span>&nbsp;/&nbsp;{totalSlides}</div>
          </div>
        </section>
        <section className="machine-params">
          {machine.frontmatter.params.map((param) => (
            <div key={param.title} className="machine-params-item">
              <div className="machine-params-item-value">
                <ReactMarkdown children={param.value} renderers={{paragraph: 'span'}} />
              </div>
              <div className="machine-params-item-title">
                {param.title}
              </div>
            </div>
          ))}
        </section>
        <section className="machine-advantages">
          <div className="machine-advantages-item">
            <div className="machine-advantages-item-inner">
              <div className="machine-advantages-item-icon-wrapper">
                  <DeliveryIcon className="machine-advantages-item-icon" />
              </div>
              <div className="machine-advantages-item-title">Delivery</div>
              <div className="machine-advantages-item-text">
                {machine.frontmatter.delivery}
              </div>
            </div>
          </div>
          <div className="machine-advantages-item">
            <div className="machine-advantages-item-inner">
              <div className="machine-advantages-item-icon-wrapper">
                  <SupportIcon className="machine-advantages-item-icon" />
              </div>
              <div className="machine-advantages-item-title">Support</div>
              <div className="machine-advantages-item-text">
                We can send technicians to your factory to install the machine and train their workers
              </div>
            </div>
          </div>
          <div className="machine-advantages-item">
            <div className="machine-advantages-item-inner">
              <div className="machine-advantages-item-icon-wrapper">
                  <GuaranteeIcon className="machine-advantages-item-icon" />
              </div>
              <div className="machine-advantages-item-title">Guarantee</div>
              <div className="machine-advantages-item-text">
                One year for mechanical parts, after-sales service is lifelong, and we offer the spare parts at preferential price long
              </div>
            </div>
          </div>
        </section>
        <section className="machine-outro">
          <div className="machine-outro-title">Need more information or want to order?</div>
          <div className="machine-outro-actions">
            <a className="machine-outro-actions-link" target="_blank" href={machine.frontmatter.presentation.publicURL}>
              <DownloadIcon className="machine-outro-actions-link-icon" />
              <span className="machine-outro-actions-link-text">Download presentation</span>
            </a>

            <a className="machine-outro-actions-button" href="mailto:info@pahhk.com">Contact us</a>
          </div>
        </section>
      </main>
    </>
  )
}

export const query = graphql`
query MachineBySlug($slug: String!) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    html
    fields {
      slug
    }
    frontmatter {
      title
      description
      id
      images {
        childImageSharp {
          fluid(quality: 100) {
            src
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      presentation { 
        publicURL 
      }
      params {
        value
        title
      }
      delivery
    }
  }
}
`

export default MachinePage
